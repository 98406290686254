//======== GLOBAL DECLARATIONS ========

//== Media querie breakpoints.
// Use with the matchMedia() method of the window object
// to execute code depending on the viewport width).
var smallBreakpoint = '601px';
var mediumBreakpoint = '993px';
var largeBreakpoint = '1201px';
var xlargeBreakpoint = '1501px';


//======== MATERIALIZE COMPONENTS INITIALIZATION ========

//== Side navigation
document.addEventListener('DOMContentLoaded', function() {
  var elems = document.querySelectorAll('.sidenav');
  var instances = M.Sidenav.init(elems);
});

//== Dropdowns
document.addEventListener('DOMContentLoaded', function() {
  var elems = document.querySelectorAll('.dropdown-trigger');
  var instances = M.Dropdown.init(elems, {
    coverTrigger: false,
    alignment : 'right',
    constrainWidth: true
  });
});

//== Tooltips
document.addEventListener('DOMContentLoaded', function() {
  var elems = document.querySelectorAll('.tooltipped');
  var instances = M.Tooltip.init(elems);
});

//== Datepickers
document.addEventListener('DOMContentLoaded', function () {
  var elems = document.querySelectorAll('.datepicker');
  var instances = M.Datepicker.init(elems, {
    format: 'yyyy-mm-dd',
    i18n: {
      labelMonthNext: 'Mois suivant',
      labelMonthPrev: 'Mois précédent',
      labelMonthSelect: 'Sélectionner le mois',
      labelYearSelect: 'Sélectionner une année',
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthsShort: ['Janv', 'Févr', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
      weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      today: 'Aujourd\'hui',
      clear: 'Réinitialiser',
      cancel: 'Fermer',
    }
  });
});

//== Selects
document.addEventListener('DOMContentLoaded', function() {
  var elems = document.querySelectorAll('select');
  var instances = M.FormSelect.init(elems);
});

//== Textarea
$(document).ready(function() {
  $('textarea').characterCounter();
});


//======== CUSTOM GLOBAL FUNCTIONS ========

//== Display message to user as a toast
// https://materializecss.com/toasts.html
// message = The message to displayLength
// type = 'success' or 'error'
function displayMessage(message, type) {
  M.toast({
    html: message,
    displayLength: 3500,
    inDuration: 300,
    outDuration: 375,
    classes: type
  });
}

function copyToClipboard(text, successMessage) {
  var input = document.createElement("input");
  input.setAttribute("value", text);
  document.body.appendChild(input);
  input.select();
  var result = document.execCommand("copy");
  document.body.removeChild(input);
  displayMessage(successMessage, "success")
  return result;
}


//== Display card's image as a background image when viewport is greater or equal to 'breakpoint';
function setFixedCardImage(breakpoint) {
  var isViewportGreaterThanBreakpoint = window.matchMedia("(min-width: " + breakpoint + ")").matches;
  $('.card-image.fixed-landscape').each(function() {
    var imageContainer = $(this);
    var image = $('img', imageContainer);
    if (isViewportGreaterThanBreakpoint) {
      imageContainer.css('backgroundImage', 'url(' + image.attr('src') + ')');
      image.hide();
    }
    else {
      imageContainer.css('backgroundImage', 'none');
      image.show();
    }
  });
}


//======== DOCUMENT READY ========
 $(document).ready(function() {

  setFixedCardImage(smallBreakpoint);

  // Window resize event.
  $(window).resize(function() {
    setFixedCardImage(smallBreakpoint);
  });

  // Add 'Select All/Deselect All' option to multiple-select-dropdown component.
  // NOTE: Select elements must have the following data attributes set : select-all-label, select-none-label
  $('ul.multiple-select-dropdown').each(function() {
    var select_all_label = $(this).siblings('select').first().data('selectAllLabel');
    $(this).prepend('<li id=sm_select_all><span>' + select_all_label + '</span></li>');
  });
  $('li#sm_select_all').on('click', function () {
    var select_element = $(this).parent('ul').siblings('select').first();
    var jq_elem = $(this),
    jq_elem_span = jq_elem.find('span'),
    select_all_label = select_element.data('selectAllLabel'),
    select_none_label = select_element.data('selectNoneLabel'),
    select_all = jq_elem_span.text() == select_all_label,
    set_text = select_all ? select_none_label : select_all_label;
    if (select_element.prop('multiple')) {
      console.log(set_text);
      jq_elem_span.text(set_text);
      jq_elem.siblings('li').filter(function() {
        return $(this).find('input').prop('checked') != select_all;
      }).click();
    }
  });
});
